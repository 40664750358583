import React from 'react';
import {  Link } from "react-router-dom";
import './Navbar.css'
const navbar= () =>{
  return (
  <div className='nav'>
    <li>
      <Link to="/">Home</Link>
    </li>
    <li>
      <Link to="/calc">Calculator</Link>
    </li>
  </div>
  );
}
export default navbar;