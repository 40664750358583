import React from 'react'
import { Link } from 'react-router-dom';
import Navbars from './components/Navbar';
const App = () => {
  return (
    <>
      <Navbars />
      <Link to="/calc"><a>click here to access the one and only siva-naigalator</a></Link>
    </>
    
  )
}
export default App;