import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Calc from './components/Calc';
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import Navbars from './components/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<App />} />
            <Route path="/calc" element={<Calc />} />
            <Route path='/nav' element={<Navbars />} />
        </Routes>
  </BrowserRouter>
  
);

